@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
/* latin-ext */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

#navbarNavMain {
    height: auto;
    min-height: 85px;
}

#navbarNav a {
    text-transform: uppercase !important;
    color: rgba(81, 81, 81, 0.86) !important;
    font-family: Raleway;
    font-weight: bold;
    font-size: 14px;
}

.red-part {
    width: 100%;
    height: 725px;
    background-color: #d16669;
    position: relative;
}

.red-part {
    color: #fff;
}

.green-part {
    width: 100%;
    height: 725px;
    background-color: #3FB795;
    -webkit-clip-path: polygon(66% 0, 100% 0, 100% 100%, 39% 100%);
    clip-path: polygon(52% 0, 100% 0, 100% 100%, 46% 100%);
    position: absolute;
    top: 0;
    right: 0;
}

.button-register {
    height: 60px;
    font-size: 2em;
    padding: 10px 90px;
    margin-left: 40%;
}

.text {
    position: absolute;
    top: 4%;
    left: 2%;
    width: 40%;
}

.title {
    font-size: 30px;
    font-family: Varela Round, Raleway;
    color: #fff;
    font-weight: bold;
    padding: 1px;
}

.paragraph {
    font-size: 13px;
    font-family: Raleway;
    color: #fff;
    text-align: justify;
    width: 83%;
}

.msg_preinsription {
    color: #fff;
    font-size: 30px;
    font-family: Varela Round, Raleway;
}

.diagonal_phone {
    position: absolute;
    right: 13%;
    height: 83%;
    top: 4%;
}

.mobile_slide {
    position: relative;
}

.center-title {
    font-size: 43px;
    font-family: Varela Round;
    color: #d16669;
    /* margin-left: 15%; */
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

#bio {
    color: #3FB795;
}

.ecran-1 {
    width: 100%;
    height: auto;
}

.ecran-2 {
    width: 94%;
    height: auto;
    margin: 0 auto;
}

.center_text {
    padding-top: 7%;
    font-size: 20px !important;
    font-family: Varela Round, Raleway;
    color: rgba(0, 0, 0, 0.62);
    text-align: justify;
    line-height: 35px !important;
    width: 100%;
    padding-left: 26px !important;
    padding-right: 26px !important;
}

.fa-red {
    color: #d16669;
}

.partenaires img {
    width: auto;
    max-width: calc(100% - 40px);
    height: auto;
    max-height: 59px;
    margin-left: 20px;
    margin-right: 20px;
}

.app-title {
    font-size: 44px;
    font-family: Varela Round;
    text-align: center;
    color: #515151;
    margin-top: 51px;
    border-top: 1px dotted rgba(0, 0, 0, 0.53);
    border-width: thick;
    width: 74% !important;
    margin-left: auto !important;
    margin-right: auto;
    padding-top: 50px;
    margin-bottom: 37px;
    font-weight: bold;
}

.footer {
    margin-top: 54px;
    background-color: #626262;
    height: auto;
    line-height: 54px;
    color: #fff;
    font-size: 10px;
    font-family: Raleway;
    font-weight: bold;
}

.footer span, .footer a {
    margin-left: 10px;
    margin-right: 10px;
}

.app-imgs img {
    width: auto;
    max-width: 100%;
    height: auto;
}

@media (min-width: 1825px) {
    .text {
        position: absolute;
        width: 34%;
        top: 17%;
        margin-left: 45px;
        left: 140px;
    }

    .title {
        font-size: 37px !important;
        font-family: Varela Round, Raleway;
        color: #fff;
        font-weight: bold;
    }

    .s_lancement {
        color: #fff;
        font-family: Raleway;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

@media (max-width: 1088px) {
    .text {
        position: absolute;
        top: 4%;
        left: 2%;
        width: 96%;
    }

    .red-part {
        height: 1000px;
    }

    .green-part {
        height: 1000px;
        clip-path: polygon(0 50%, 100% 41%, 100% 100%, 0% 100%) !important;
    }

    .diagonal_phone {
        height: 41% !important;
        top: 50%;
        left: 50%;
        margin-left: -20.5%;
    }

    .lancement {
        position: absolute;
        top: 55% !important;
    }
}

.sd-home {
    display: none;
}

.mobile_title {
    color: #fff;
    font-family: Varela Round, Raleway;
    font-size: 28px !important;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 20px;
    position: absolute;
    top: 6% !important;
}

.lancement {
    position: absolute;
    top: 35%;
    right: 24%;
    text-align: center;
    background-color: #d16669;
    height: 116px;
    padding: 13px;
    border-radius: 50%;
    color: #fff;
    font-family: Raleway;
    font-weight: bold;
    font-size: 39px;
    line-height: 33px;
}

@media (max-width: 749px) {
    .green-part {
        clip-path: polygon(0 0, 100% 0, 100% 38%, 0 46%) !important;
        height: 600px !important;
    }

    .red-part {
        height: 600px !important;
        background-color: transparent;
    }

    .hd-home {
        display: none !important;
    }

    .sd-home {
        display: block;
    }

    .diagonal_phone {
        top: 24%;
    }

    .lancement {
        top: 29% !important;
        right: 15% !important;
        text-align: center;
        background-color: #d16669;
        height: 106px;
        padding: 9px;
        border-radius: 50%;
        font-size: 31px;
        line-height: 32px;
    }

    .diagonal_phone {
        width: 61% !important;
        height: auto !important;
        margin-left: -30.5% !important;
    }
}

@media (min-width: 649px) {
    .inscription_submit, .register_submit {
        color: #ffffff !important;
        background-color: #3bb58e !important;
        border-color: #ffffff !important;
        margin-top: 15px;
        height: 35px;
        border: 1px solid #3bb58e;
        border-radius: 4px;
        margin-left: 3%;
        margin-left: 52%;
    }

    .button-register {
        height: 60px;
        line-height: 40px;
        vertical-align: middle;
        font-size: 2em;
        padding: 10px 90px;
        margin-left: 40%;
        display: inline-block;
    }
}

.s_lancement {
    color: #fff;
    font-family: Raleway;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
}

.offre a {
    color: rgba(81, 81, 81, 0.86) !important;
    text-decoration: none !important;
    font-family: Raleway;
}

@media (max-width: 464px) {
    .lancement {
        top: 35% !important;
        right: 13% !important;
        text-align: center;
        background-color: #d16669;
        height: 90px;
        padding: 9px;
        border-radius: 50%;
        font-size: 25px;
        line-height: 26px;
        width: 96px;
    }

    .diagonal_phone {
        top: 30%;
        width: 75% !important;
        height: auto !important;
        margin-left: -39.5% !important;
    }

    .s_lancement {
        font-size: 15px;
    }
}

#baseContent {
    width: auto;
    height: auto;
    min-height: calc(100vh - 193px);
}

.back-title {
    margin-left: 15px;
    margin-top: 0px;
    font-family: Raleway;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.55);
    font-size: 40px;
}

.offre {
    border: 1px solid rgba(0, 0, 0, 0.17);
    margin: 30px;
    height: 285px;
    position: relative;
    border-radius: 2px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.13);
    box-sizing: content-box;
}

.deal {
    margin: 30px;
    height: auto;
    position: relative;
    border-radius: 2px;
    box-sizing: content-box;
}

.img_pdt {
    margin-left: 18%;
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
    margin-top: 19%;
}

.deal_pdt {
    width: auto;
    height: auto;
    max-width: 250px;
    max-height: 250px;
}

.picto-deal {
    width: 100%;
    max-width: 70px;
    margin: 5px;
}

.amount-deal {
    margin-left: 12px;
}

.msg-deal-deal {
    font-size: 16px;
    font-weight: normal;
    margin-left: -6px;
    margin-top: 5px;
    display: block;
}

.marque_name {
    position: absolute;
    bottom: 0;
    right: 4%;
    left: 4%;
}

.title_pdt {
    text-align: center;
    font-family: Raleway;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
}

.reduc_div {
    position: absolute;
    top: 30%;
    right: -8px;
}

.reduc-deal_div {
    position: absolute;
    top: 30%;
    right: -60px;
}

.html-deal div:first-child {
    color: rgba(0, 0, 0, 0.55) !important;
    font-weight: bold !important;
    font-family: Raleway !important;
}

.html-deal {
    color: rgba(0, 0, 0, 0.55);
    font-family: Raleway;
}

.reduc {
    font-family: Raleway;
    font-size: 20px;
    color: rgb(229, 91, 101);
    font-weight: bold;
    float: right;
    line-height: 14px;
}

.picto {
    width: 44%;
    max-width: 56px;
    margin: 5px;
}

.amount {
    margin-right: 12px;
}

.msg-deal {
    font-size: 10px;
    font-weight: normal;
    margin-left: -6px;
}

.search {
    height: 37px;
    border: 1px solid rgba(0, 0, 0, 0.32);
    padding: 10px;
    border-radius: 3px;
    width: 58%;
    margin-right: 15px;
    margin-top: 8px;
    float: right;
}

.img_modal {
    max-width: 500px;
    max-height: 500px;
    width: 100%;
}

.intermodal {
    position: relative;
}

.reduc_modal {
    font-family: Raleway;
    font-size: 30px;
    color: rgb(229, 91, 101);
    margin-bottom: 0;
    font-weight: bold;
}

.modal-price-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.msg-deal_modal {
    font-size: 15px;
    font-weight: normal;
    margin-left: 7px;
    color: #e55b65;
}

.title_pdt {
    text-align: center;
    font-family: Raleway;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
}

.inside {
    overflow-y: auto;
    max-height: 160px;
}

.overflow-hidden {
    overflow: hidden !important;
}

.msg-deal_modal {
    font-size: 15px;
    font-weight: normal;
    margin-left: 7px;
    color: #e55b65;
}

.number {
    text-align: center;
    color: #42bc99;
    font-family: Raleway;
    font-size: 51px;
    border: 4px solid #42bc99;
    border-radius: 50%;
    width: 75px;
    margin: auto;
}

.text_ccm {
    font-family: Raleway;
    font-size: 21px;
    margin-top: 11%;
    text-align: center;
}

.img-ccm {
    width: 300px;
    height: auto;
    float: left;
}

.img-ccm img {
    width: auto;
    height: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.text-content-ccm {
    width: calc(100% - 300px);
    float: left;
}

@media (max-width: 667px) {
    .img-ccm {
        width: 100%;
        height: auto;
        float: none;
        text-align: center;
    }

    .text-content-ccm {
        width: 100%;
        float: none;
    }

    .img-ccm img {
        max-width: 300px;
    }
}

/*centre daide*/
.panel-default > .panel-heading {
    color: #e55b65 !important;
    background-color: rgba(80, 192, 159, 0.24) !important;
}

a {
    color: rgba(81, 81, 81, 0.86) !important;
    text-decoration: none !important;
    font-family: Raleway;
}

.accod {
    margin-top: 3%;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ddd;
    font-family: Raleway !important;
    color: rgba(0, 0, 0, 0.38) !important;
    font-weight: bold !important;
    font-size: 15px !important;
}

.panel-default {
    border-color: rgba(229, 91, 101, 0.42);;
}

.inscription_submit, .register_submit {
    color: #ffffff !important;
    background-color: #3bb58e !important;
    border-color: #ffffff !important;
    margin-top: 15px;
    height: 35px;
    border: 1px solid #3bb58e;
    border-radius: 4px;
    margin-left: 3%;
}

.inscription_form {
    margin-top: 10%;
    margin-bottom: 7%;
}

.label_inscription_form {
    font-family: Raleway;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.55);
}


.input_f_inscription {
    background-color: rgba(59, 181, 142, 0.1);
    border: 1px solid rgba(60, 181, 142, 0.45);
}

.sexe {
    color: rgba(224, 80, 91, 0.67);
}

.sexe_div {
    margin: auto;
}

.logomobile {
    float: left;
}

@media (max-width: 1054px) {
    body.front {
        margin-top: 0px !important;
    }

    .zone_menu {
        display: none;
    }
}

.mobile {
    padding-top: 2%;
    /* float: right; */
    /* margin-right: 20%; */
    position: absolute;
    left: 28%;
    top: 5px !important;
}

.white_logo_mobile {
    width: 23px;
    margin-left: 24px;
    margin-top: 6px;
}


.telachargement {
    margin-bottom: 45px;
}

.accod a {
    color: rgba(81, 81, 81, 0.86);
    text-decoration: none !important;
    font-family: Raleway;
    font-size: 16px !important;
    margin-left: 5px !important;
}

.panel-default {
    border-color: rgba(229, 91, 101, 0.42);
}

.footer a {
    color: #fff !important;
}

.login_submit {
    color: #ffffff !important;
    background-color: #e55b65 !important;
    border-color: #ffffff !important;
    margin-top: 15px;
}

#preinscription a {
    line-height: 46px !important;
    height: auto !important;
}

.input_f_inscription {
    background-color: rgba(59, 181, 142, 0.1);
    border: 1px solid rgba(60, 181, 142, 0.45);
}

.form-group-element {
    position: relative;
}

.loader-form, .loader-finish-form {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: -30px;
}

.loader-finish-form {
    color: limegreen;
}

.iban-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 300px;
}

/* refund cards */
.refund-card {
    border: 1px solid rgba(0, 0, 0, .12);
    position: relative;
}

.refund-card:hover {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, .08), 0 0 15px 0 rgba(0, 0, 0, .02), 0 0 20px 4px rgba(0, 0, 0, .06);
}

.refund-date {
    font-size: 15px;
    color: #c8c8c8;
    font-weight: bold;
}

.refund-card h3 {
    font-size: 18px;
}

.status-refund {
    position: relative;
}

.status-refund p {
    margin-bottom: 0;
    width: calc(100% - 20px);
    margin-left: 20px;
}

.status-refund span {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.cancel-refund {
    color: red;
}

.cancel-refund span {
    background-color: red;
}

.tovalid-refund {
    color: orange;
}

.tovalid-refund span {
    background-color: orange;
}

.valid-refund {
    color: green;
}

.valid-refund span {
    background-color: green;
}

.ko-refund {
    color: grey;
}

.ko-refund span {
    background-color: grey;
}

.ticket-refund {
    color: blue;
}

.ticket-refund span {
    background-color: blue;
}

.action-refund {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .12);
}

.action-refund button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
}

.refund-card:hover .action-refund {
    display: block;
}

#ticket-forms-inputs, #deal-form-content {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100000;
    width: 100vw;
    height: 100vh;
}

#ticket-forms {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100001;
}

.ticket-img {
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 300px;
    margin: 0 auto;
}

#ticket-content-img {
    text-align: center;
}

#deal-form-content-elem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.inputs-shops {
    width: 100%;
    height: auto;
    max-height: 100px;
    overflow-y: auto;
}

.inputs-shops button {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 30px;
    padding: 5px 5px 0 5px;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    text-align: left;
    outline: none;
}

.inputs-shops button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.selected-shop {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

#nav-bar-burger {
    display: none;
}

@media (max-width: 991px) {
    .navbar-nav {
        flex-direction: column-reverse !important;
        align-items: flex-end !important;
    }

    .img-ccm {
        display: none !important
    }

    #nav-bar-burger {
        display: block;
    }
}

.footer button {
    color: #fff;
    background-color: transparent;
    display: inline;
    outline: none;
    padding: 0;
    margin: 0 5px 0 5px;
    border-radius: 0;
    border: none;
}

.form-content-deal {
    max-height: 80vh;
    overflow-y: auto;
}

#call-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10000000;
}

#loader-element {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000001;
    background-color: transparent;
}

#loader-element img {
    width: 250px;
    height: auto;
}

@media(max-width: 575px) {
    .inscription_submit {
        margin-left: 0;
    }
}

.email-sucess
{
    background-color: #d5f0e8;
}

.email-fail
{
    background-color: rgba(229, 91, 101, 0.42);
}
